/*google fonts Roboto Condensed for text-body  Catamaran for title*/
@import url("https://fonts.googleapis.com/css2?family=Catamaran&family=Montserrat:wght@200&family=Raleway:wght@200&family=Roboto+Condensed&family=Ubuntu&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=JetBrains+Mono:ital,wght@0,100;0,400;1,200&family=Roboto+Mono:wght@600&family=Space+Mono:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
